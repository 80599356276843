import { Token } from 'api/template'
import { UserSignature } from 'api/user-signature'
import { IconFieldSignature } from 'icons'
import { FC, useCallback } from 'react'
import { Button, useField } from 'ui'
import { useFieldControl } from 'ui/form/field/_base/use-field-control'
import { cn } from 'utils'
import { useSignatureContext } from './signature-context'
import styles from './signature-field.module.scss'

type Props = {
  className?: string
  token: Token.Signature
  onUpdate: ReturnType<typeof useFieldControl<string | null>>[1]
  id: string
  name: string
  disabled?: boolean
}

export const SignaturePersistentField: FC<Props> = ({ className, token, onUpdate, ...props }) => {
  const subtype = token.subtype || UserSignature.Type.SIGNATURE
  if (!UserSignature.isValidPersistentType(subtype))
    throw new Error('Wet signature not supported by <PersistentSignatureField />.')
  const [signatures, getSignature] = useSignatureContext()

  const { blob, snapshot_id } = signatures[subtype] ?? {}
  const state = useField(token.token_id)
  const checked = !!state?.value

  const handleClick = useCallback(async () => {
    const next = checked ? null : snapshot_id ?? (await getSignature(subtype)).snapshot_id
    onUpdate({
      valid: !!next,
      touched: true,
      value: next,
      validationMessage: next ? null : 'Required',
    })
  }, [checked, getSignature, onUpdate, snapshot_id, subtype])

  const content =
    checked && blob && snapshot_id ? (
      <img className={cn(styles.img, className)} src={URL.createObjectURL(blob)} alt="signature" />
    ) : (
      <>
        <IconFieldSignature />
        <span>Sign</span>
      </>
    )

  return (
    <Button
      theme="none"
      {...props}
      onClick={handleClick}
      onBlur={() => onUpdate({ touched: true })}
      className={cn(
        styles.button,
        className,
        state?.touched && state?.validationMessage && styles.invalid,
        checked && styles.checked,
      )}
    >
      {content}
    </Button>
  )
}
