import { UserSignature, userSignature as userSignatureApi } from 'api/user-signature'
import { FC, useRef, useCallback, useEffect, ComponentProps, CSSProperties } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { ActionButton, Button, Description, useContainerZoom } from 'ui'
import { cn } from 'utils'
import { alertSuccessMessage, alertErrorMessage } from 'utils/toast'
import styles from './draw.module.scss'
import { SIGNATURE_SIZE } from '../const'

interface Props {
  width?: number
  height?: number
  className?: string
  message?: string
  onSuccess?: (data: { snapshot_id: string; blob: Blob }) => void
  image?: string | null
  type?: UserSignature.Type
  onBeforeUpload?: (blob: Blob) => void
  submitLabel?: string
}
export const SignatureDraw: FC<Props> = ({
  className,
  message = UserSignature.MSG.ACTION.UPLOAD_SUCCESS,
  onSuccess,
  onBeforeUpload,
  image,
  type = UserSignature.Type.SIGNATURE,
  submitLabel = UserSignature.MSG.ACTION.UPLOAD,
}) => {
  const containerRef = useRef(null)
  const ref = useRef<SignatureCanvas>(null)
  const SIZE = SIGNATURE_SIZE[type]

  const zoom = useContainerZoom(containerRef, {
    pageWidth: SIZE.width,
  })

  useEffect(() => {
    if (!image) return
    const tempImg = new Image()
    tempImg.addEventListener('load', function () {
      const context = ref.current?.getCanvas()?.getContext('2d')
      context?.reset()
      context?.drawImage(
        tempImg,
        Math.round((SIZE.width * zoom - tempImg.width * zoom) / 2),
        Math.round((SIZE.height * zoom - tempImg.height * zoom) / 2),
        tempImg.width * zoom,
        tempImg.height * zoom,
      )
    })
    tempImg.setAttribute('src', image)
  }, [SIZE.height, SIZE.width, image, zoom])

  const upload = useCallback(async () => {
    try {
      if (ref.current?.isEmpty()) throw new Error(UserSignature.MSG.ERR.EMPTY)
      const file = await new Promise<Blob>((resolve, reject) => {
        ref.current
          ?.getTrimmedCanvas()
          .toBlob((blob) => (blob ? resolve(blob) : reject()), 'image/png')
      })
      onBeforeUpload?.(file)
      const { snapshot_id } = await userSignatureApi.upload({
        file,
        type: type ?? UserSignature.Type.SIGNATURE,
      })
      alertSuccessMessage(message)
      onSuccess?.({ snapshot_id, blob: file })
    } catch (error) {
      alertErrorMessage(error as Error)
    }
  }, [message, onSuccess, type, onBeforeUpload])

  const clear = useCallback(() => ref.current?.clear(), [])
  const undo = useCallback(() => {
    const data = ref.current?.toData()
    if (data) {
      data.pop() // remove the last dot or line
      ref.current?.fromData(data)
    }
  }, [])

  return (
    <div ref={containerRef}>
      <Description className={styles.description}>
        Draw your signature here using your mouse or trackpad.
      </Description>

      <div className={cn(styles.content, className)}>
        <div className={styles.actions}>
          <Button onClick={undo} theme="secondary" className={styles.button}>
            undo
          </Button>
          <Button onClick={clear} theme="secondary" className={styles.button}>
            clear
          </Button>
        </div>
        <div
          className={styles.wrapper}
          data-signature-type={type}
          style={{ '--zoom': zoom } as CSSProperties}
        >
          <SignatureCanvas
            canvasProps={
              {
                width: SIZE.width * zoom,
                height: SIZE.height * zoom,
                className: styles.signature,
              } as ComponentProps<'canvas'>
            }
            ref={ref}
          />
        </div>
      </div>
      <footer>
        <ActionButton theme="primary" onClick={upload}>
          {submitLabel}
        </ActionButton>
      </footer>
    </div>
  )
}
